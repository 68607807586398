export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

export const UPDATE_MOMENT = 'UPDATE_MOMENT';

export const FETCH_SLEEP_FOR_DAY_REQUEST = 'FETCH_SLEEP_FOR_DAY_REQUEST';
export const FETCH_SLEEP_FOR_DAY_SUCCESS = 'FETCH_SLEEP_FOR_DAY_SUCCESS';
export const FETCH_SLEEP_FOR_DAY_FAILURE = 'FETCH_SLEEP_FOR_DAY_FAILURE';

// export const CREATE_PROJECT_PLAN_REQUEST = 'CREATE_PROJECT_PLAN_REQUEST';
// export const CREATE_PROJECT_PLAN_SUCCESS = 'CREATE_PROJECT_PLAN_SUCCESS';
// export const CREATE_PROJECT_PLAN_FAILURE = 'CREATE_PROJECT_PLAN_FAILURE';
